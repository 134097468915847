import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from 'react-query'

import {create} from "zustand";
import { persist, devtools } from "zustand/middleware";

import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';



import './asset/css/button.css';
import './asset/css/style.css';
import './asset/css/contact.css';
import './asset/css/flight-details.css';
import './asset/css/admin.css';
import './asset/css/login.css';
import './asset/css/sight-seeing.css';

import 'bootstrap/dist/css/bootstrap.css';


import { LogoProvider } from './logoContext';


const queryClient = new QueryClient(
  {
    defaultOptions: {
      queries: {
        staleTime: 10* 60 * 1000, // 10 minutes
        cacheTime: 10 * 60 * 1000 // 10 minutes
      }
    }
  }
)
console.warn = () => {};


export const useStore = create(
  devtools(
    persist(
      (set) => ({
        userStatus: false,
        accessToken: null,
        refreshToken: null,
      }),
      {
        name: "auth-storage",
        getStorage: () => localStorage,
      }
    )
  )
);

export const useGuestStore = create(
  devtools(
    persist(
      (set) => ({
        userStatus: false,
        id: "",
        name: "",
        phone: ""
      }),
      {
        name: "guest-login",
        getStorage: () => localStorage,
      }
    )
  )
);

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
          <ScrollToTop />
          <StyledChart />
          <LogoProvider>
          <Router />
          </LogoProvider>
          <ToastContainer /> {/* Add this line to render toasts */}
          </QueryClientProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
