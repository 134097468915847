import SvgColor from '../../../components/svg-color';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Guests Registration',
    path: '/admin/guest-registration',
    icon: icon('ic_registration'),
  },

  {
    title: 'Guests Listing',
    path: '/admin/guests-listing',
    icon: icon('ic_listing'),
  },

  // {
  //   title: 'Agenda Creation',
  //   path: '/admin/agenda-creation',
  //   icon: icon('ic_registration'),
  // },

  {
    title: 'Agenda',
    path: '/admin/agenda-listing',
    icon: icon('ic_listing'),
  },

  // {
  //   title: 'Send Email Notification',
  //   path: '/admin/send-email',
  //   icon: icon('ic_sendEmail'),
  // },
];
export default navConfig;
