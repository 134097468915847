import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import LoginPage from './pages/admin/login';
import Page404 from './pages/Page404';

import GuestListing from './pages/admin/guests-listing';
import AgendaListing from './pages/admin/agenda-listing';
import GuestsCreation from './pages/admin/guests-creation';
import SendEmailPage from './pages/admin/send-email-page';

import HomePage from './pages/guest/home';
import AgendaPage from './pages/guest/agenda';

import SightSeeingPage from './pages/guest/sight-seeing';
import FlightDetailsPage from './pages/guest/flight-details';
import ContactUSPage from './pages/guest/contact-us';

import GuestLoginPage from './pages/guest/guest-login';

import { useStore, useGuestStore } from './App';
import PhotoGalleryPage from './pages/guest/photo-gallery';
import TransportationPage from './pages/guest/transportation';
import MoreInfoPage from './pages/guest/more-info';


const AdminRoute = ({ children }) => {

  const userStatus = useStore((state) => state.userStatus);
  
  return (

      userStatus === true ? children : 
      <Navigate to="/admin/sign-in" />

  )
}

const GuestRoute = ({ children }) => {

  const guestPhone = useGuestStore((state) => state.phone);
  
  return (

      !!guestPhone === true ? children : 
      <Navigate to="/guest-login" />

  )
}

const RedirectAdmin = ({ children }) => {
  
  const userStatus = useStore((state) => state.userStatus);
  
  return(

    userStatus === true ? <Navigate to="/admin/guest-registration" /> : children
    )

}

const RedirectGuest = ({ children }) => {
  
  const userPhone = useGuestStore((state) => state.phone);
  
  return(
    
    !!userPhone  === true ? <Navigate to="/home" /> : children

    )
}

export default function Router() {
  const routes = useRoutes([
    // {
    //   path: '/',
    //   element: <DashboardLayout />,
    //   children: [
    //     { element: <Navigate to="/guest-login" />, index: true },

    //     { path: 'admin/guest-registration', element: <AdminRoute><GuestsCreation /></AdminRoute> },
    //     { path: 'admin/guests-listing', element: <AdminRoute><GuestListing /></AdminRoute> },
    //     { path: 'admin/agenda-listing', element: <AdminRoute><AgendaListing /></AdminRoute> },
    //     { path: 'admin/send-email', element: <AdminRoute><SendEmailPage /></AdminRoute> },
    //   ],
    // },

    // {
    //   path: 'admin/sign-in',
    //   element: <RedirectAdmin><LoginPage /></RedirectAdmin>,
    // },

    // {
    //   element: <SimpleLayout />,
    //   children: [
    //     { element: <Navigate to="/login" />, index: true },
    //     { path: '404', element: <Page404 /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },

    // {
    //   path: '/guest-login',
    //   element: <RedirectGuest><GuestLoginPage /></RedirectGuest>,
    // },
    // {
    //   path: '/home',
    //   element: <GuestRoute><HomePage/></GuestRoute>,
    // },
    // {
    //   path: '/agenda',
    //   element: <GuestRoute><AgendaPage /></GuestRoute>,
    // },

    // {
    //   path: '/sight-seeing',
    //   element: <GuestRoute><SightSeeingPage /></GuestRoute>,
    // },
    // {
    //   path: '/flight-details',
    //   element: <GuestRoute><FlightDetailsPage /></GuestRoute>,
    // },
    // {
    //   path: '/contact-support',
    //   element: <GuestRoute><ContactUSPage /></GuestRoute>,
    // },
    {
      path: '/',
      element: <PhotoGalleryPage />,
    },
    // {
    //   path: '/transportation',
    //   element: <GuestRoute><TransportationPage /></GuestRoute>,
    // },
    // {
    //   path: '/more-info',
    //   element: <GuestRoute><MoreInfoPage /></GuestRoute>,
    // },

    // {
    //   path: '*',
    //   element: <Navigate to="/404" replace />,
    // },
  ]);

  return routes;
}
