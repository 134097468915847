import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { useLogo } from '../../logoContext';


import TowerImage from '../../asset/images/hotel/ferris-wheel.svg';
import strip from '../../asset/images/strip.svg'

import videoIcon from '../../asset/icons/1.png'
import groupPhotoIcon from '../../asset/icons/2.png'
import pictures from '../../asset/icons/3.png'



import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from "lightgallery/plugins/video";



import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-video.css";


import groupPhoto from '../../asset/gallery/group-photo.jpg'

import { toast } from 'react-toastify';

import photo1 from '../../asset/gallery/1.jpg';
import photo2 from '../../asset/gallery/2.jpg';
import photo3 from '../../asset/gallery/3.jpg';
import photo4 from '../../asset/gallery/4.jpg';
import photo5 from '../../asset/gallery/5.jpg';
import photo6 from '../../asset/gallery/6.jpg';
import photo7 from '../../asset/gallery/7.jpg';
import photo8 from '../../asset/gallery/8.jpg';
import photo9 from '../../asset/gallery/9.jpg';
import photo10 from '../../asset/gallery/10.jpg';
import photo11 from '../../asset/gallery/11.jpg';
import photo12 from '../../asset/gallery/12.jpg';
import photo13 from '../../asset/gallery/13.jpg';
import photo14 from '../../asset/gallery/14.jpg';
import photo15 from '../../asset/gallery/15.jpg';
import photo16 from '../../asset/gallery/16.jpg';
import photo17 from '../../asset/gallery/17.jpg';
import photo18 from '../../asset/gallery/18.jpg';
import photo19 from '../../asset/gallery/19.jpg';
import photo20 from '../../asset/gallery/20.jpg';
import photo21 from '../../asset/gallery/21.jpg';
import photo22 from '../../asset/gallery/22.jpg';
import photo23 from '../../asset/gallery/23.jpg';
import photo24 from '../../asset/gallery/24.jpg';
import photo25 from '../../asset/gallery/25.jpg';
import photo26 from '../../asset/gallery/26.jpg';
import photo27 from '../../asset/gallery/27.jpg';
import photo28 from '../../asset/gallery/28.jpg';
import photo29 from '../../asset/gallery/29.jpg';
import photo30 from '../../asset/gallery/30.jpg';
import photo31 from '../../asset/gallery/31.jpg';
import photo32 from '../../asset/gallery/32.jpg';
import photo33 from '../../asset/gallery/33.jpg';
import photo34 from '../../asset/gallery/34.jpg';
import photo35 from '../../asset/gallery/35.jpg';
import photo36 from '../../asset/gallery/36.jpg';
import photo37 from '../../asset/gallery/37.jpg';
import photo38 from '../../asset/gallery/38.jpg';
import photo39 from '../../asset/gallery/39.jpg';
import photo40 from '../../asset/gallery/40.jpg';
import photo41 from '../../asset/gallery/41.jpg';
import photo42 from '../../asset/gallery/42.jpg';
import photo43 from '../../asset/gallery/43.jpg';
import photo44 from '../../asset/gallery/44.jpg';
import photo45 from '../../asset/gallery/45.jpg';
import photo46 from '../../asset/gallery/46.jpg';
import photo47 from '../../asset/gallery/47.jpg';
import photo48 from '../../asset/gallery/48.jpg';
import photo49 from '../../asset/gallery/49.jpg';
import photo50 from '../../asset/gallery/50.jpg';
import photo51 from '../../asset/gallery/51.jpg';
import photo52 from '../../asset/gallery/52.jpg';
import photo53 from '../../asset/gallery/53.jpg';
import photo54 from '../../asset/gallery/54.jpg';
import photo55 from '../../asset/gallery/55.jpg';
import photo56 from '../../asset/gallery/56.jpg';
import photo57 from '../../asset/gallery/57.jpg';
import photo58 from '../../asset/gallery/58.jpg';
import photo59 from '../../asset/gallery/59.jpg';
import photo60 from '../../asset/gallery/60.jpg';
import photo61 from '../../asset/gallery/61.jpg';
import photo62 from '../../asset/gallery/62.jpg';
import photo63 from '../../asset/gallery/63.jpg';
import photo64 from '../../asset/gallery/64.jpg';
import photo65 from '../../asset/gallery/65.jpg';
import photo66 from '../../asset/gallery/66.jpg';
import photo67 from '../../asset/gallery/67.jpg';
import photo68 from '../../asset/gallery/68.jpg';
import photo69 from '../../asset/gallery/69.jpg';
import photo70 from '../../asset/gallery/70.jpg';
import photo71 from '../../asset/gallery/71.jpg';
import photo72 from '../../asset/gallery/72.jpg';
import photo73 from '../../asset/gallery/73.jpg';
import photo74 from '../../asset/gallery/74.jpg';
import photo75 from '../../asset/gallery/75.jpg';
import photo76 from '../../asset/gallery/76.jpg';
import photo77 from '../../asset/gallery/77.jpg';
import photo78 from '../../asset/gallery/78.jpg';
import photo79 from '../../asset/gallery/79.jpg';
import photo80 from '../../asset/gallery/80.jpg';
import photo81 from '../../asset/gallery/81.jpg';
import photo82 from '../../asset/gallery/82.jpg';
import photo83 from '../../asset/gallery/83.jpg';
import photo84 from '../../asset/gallery/84.jpg';
import photo85 from '../../asset/gallery/85.jpg';
import photo86 from '../../asset/gallery/86.jpg';
import photo87 from '../../asset/gallery/87.jpg';
import photo88 from '../../asset/gallery/88.jpg';
import photo89 from '../../asset/gallery/89.jpg';
import photo90 from '../../asset/gallery/90.jpg';
import photo91 from '../../asset/gallery/91.jpg';
import photo92 from '../../asset/gallery/92.jpg';
import photo93 from '../../asset/gallery/93.jpg';
import photo94 from '../../asset/gallery/94.jpg';
import photo95 from '../../asset/gallery/95.jpg';
import photo96 from '../../asset/gallery/96.jpg';
import photo97 from '../../asset/gallery/97.jpg';
import photo98 from '../../asset/gallery/98.jpg';
import photo99 from '../../asset/gallery/99.jpg';
import photo100 from '../../asset/gallery/100.jpg';
import photo101 from '../../asset/gallery/101.jpg';
import photo102 from '../../asset/gallery/102.jpg';
import photo103 from '../../asset/gallery/103.jpg';
import photo104 from '../../asset/gallery/104.jpg';
import photo105 from '../../asset/gallery/105.jpg';
import photo106 from '../../asset/gallery/106.jpg';
import photo107 from '../../asset/gallery/107.jpg';
import photo108 from '../../asset/gallery/108.jpg';
import photo109 from '../../asset/gallery/109.jpg';
import photo110 from '../../asset/gallery/110.jpg';
import photo111 from '../../asset/gallery/111.jpg';
import photo112 from '../../asset/gallery/112.jpg';
import photo113 from '../../asset/gallery/113.jpg';
import photo114 from '../../asset/gallery/114.jpg';
import photo115 from '../../asset/gallery/115.jpg';
import photo116 from '../../asset/gallery/116.jpg';
import photo117 from '../../asset/gallery/117.jpg';
import photo118 from '../../asset/gallery/118.jpg';
import photo119 from '../../asset/gallery/119.jpg';
import photo120 from '../../asset/gallery/120.jpg';
import photo121 from '../../asset/gallery/121.jpg';
import photo122 from '../../asset/gallery/122.jpg';
import photo123 from '../../asset/gallery/123.jpg';
import photo124 from '../../asset/gallery/124.jpg';
import photo125 from '../../asset/gallery/125.jpg';
import photo126 from '../../asset/gallery/126.jpg';
import photo127 from '../../asset/gallery/127.jpg';
import photo128 from '../../asset/gallery/128.jpg';
import photo129 from '../../asset/gallery/129.jpg';
import photo130 from '../../asset/gallery/130.jpg';
import photo131 from '../../asset/gallery/131.jpg';
import photo132 from '../../asset/gallery/132.jpg';
import photo133 from '../../asset/gallery/133.jpg';
import photo134 from '../../asset/gallery/134.jpg';
import photo135 from '../../asset/gallery/135.jpg';
import photo136 from '../../asset/gallery/136.jpg';
import photo137 from '../../asset/gallery/137.jpg';
import photo138 from '../../asset/gallery/138.jpg';
import photo139 from '../../asset/gallery/139.jpg';
import photo140 from '../../asset/gallery/140.jpg';
import photo141 from '../../asset/gallery/141.jpg';
import photo142 from '../../asset/gallery/142.jpg';
import photo143 from '../../asset/gallery/143.jpg';
import photo144 from '../../asset/gallery/144.jpg';
import photo145 from '../../asset/gallery/145.jpg';
import photo146 from '../../asset/gallery/146.jpg';
import photo147 from '../../asset/gallery/147.jpg';
import photo148 from '../../asset/gallery/148.jpg';
import photo149 from '../../asset/gallery/149.jpg';
import photo150 from '../../asset/gallery/150.jpg';
import photo151 from '../../asset/gallery/151.jpg';
import photo152 from '../../asset/gallery/152.jpg';
import photo153 from '../../asset/gallery/153.jpg';



const PhotoGalleryPage = () => {


  document.title = 'Gallery | SNB Offsite Meeting';

  const logoSrc = useLogo();

  const [videoBase64, setVideoBase64] = useState(false);

 

  // useEffect(() => {

    const fetchData = async () => {
      try {
        toast.info('Your download will start shortly!')
        fetch('https://2024.som2022.com/api/accounts/get-video')
        .then(res => res.json())
        .then((res) => {

          const link = document.createElement('a');
          link.setAttribute('href', `data:video/mp4;base64,${res['base_64']}`);
        
          link.setAttribute('download', 'video.mp4');
        
          document.body.appendChild(link);
          link.click()
        })

        // Update the state with the fetched base64 data
        
      } catch (error) {
        console.error('Error fetching video data');
      }
    };


  const afterOpen = () => {

    
    const downloadBtn = document.querySelectorAll('.lg-download')[0]

    if (downloadBtn){
      
      downloadBtn.onclick = () => {
        fetchData()
      }
    }
  }
    
    
  return (
    <div className='outer-wrapper'>
      <div className='container-fluid main-content'>
        <div className='row header'>
          <Link style={{ width: 'fit-content' }} to="/"><img className='ms-sm-3 ms-0' id='logo' src={logoSrc} alt='snb logo' /></Link>
          <h2 className='text-primary header-text'>2024 STRATEGY OFFSITE MEETING</h2>
        </div>

        <div className='coming-soon-content'>
          <h2 className='text-primary header-text'>GALLERY</h2>

          <div className='container'>
            <div className='row icons-row d-flex justify-content-center'>

              <div className='col-1 d-md-block d-none'></div>

              <div className='col-10 col-md-3 d-flex justify-content-center flex-column align-items-center icon-div'>
                <img onClick={() => document.getElementsByClassName('img-responsive')[0].click()} className='video-icon' src={videoIcon} alt="" />
                <h3 onClick={() => document.getElementsByClassName('img-responsive')[0].click()} className='text-tertiary'>Video</h3>
              </div>
              

              <div className='col-10 col-md-3 d-flex justify-content-center flex-column align-items-center icon-div'>
                <img onClick={() => document.getElementById('lg-group').click()} className='group-icon' src={groupPhotoIcon} alt="" />
                <h3 onClick={() => document.getElementById('lg-group').click()} className='text-tertiary'>Group Photo</h3>
              </div>


              <div className='col-10 col-md-3 d-flex justify-content-center flex-column align-items-center icon-div'>
                <img onClick={() => document.getElementById('lg-pictures').click()} className='pictures-icon' src={pictures} alt="" />
                <h3 onClick={() => document.getElementById('lg-pictures').click()} className='text-tertiary'>Pictures</h3>
              </div>

              <div className='col-1 d-md-block d-none'></div>
            </div>
          </div>

          <div className='p-relative'>

                        <LightGallery
                       
                          speed={500}
                          plugins={[lgVideo]}
                          onAfterOpen={afterOpen}
                          mobileSettings={
                            { download: true }
                          }>
                              
                         <a
                            className="gallery-item"
                            data-video='{"source": [{"src":"https://som2022.com/assets/video.mp4", "type":"video/mp4"}], "tracks": [{"src": "{/videos/title.txt", "kind":"captions", "srclang": "en", "label": "English", "default": "true"}], "attributes": {"preload": false, "playsinline": true, "controls": true}}'
                            key="4"
                          >
                                  <img
                                    style={{ maxWidth: '400px' , display:'none'}}
                                    className="img-responsive"
                                    alt=""
                                    src="https://img.youtube.com/vi/egyIeygdS_E/maxresdefault.jpg"
                                  />
                                </a>
                      
                        </LightGallery>

                          <LightGallery
                            speed={500}
                            plugins={[lgVideo]}
                            mobileSettings={{ download: true }}
                          >
                            <a
                              className="gallery-item"
                              key="4">
                              <img src={videoIcon} id='lg-video' className='d-none'/>
                            </a>
                          </LightGallery>


                              {/* for group photo */}
                          <LightGallery
                            
                            speed={500}
                            plugins={[lgZoom]}
                            mobileSettings={
                              { download: true }
                            }
                          >
                           <a            
                              id='img-link'
                              href={groupPhoto}
                            >
                      
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                      
                              </a>
                       
                          </LightGallery>
                          
                          
                          {/* for pictures */}
                          <LightGallery
                            
                            speed={500}
                            plugins={[lgZoom]}
                            mobileSettings={
                              { download: true }
                            }
                          >

                            <a id='lg-pictures' href={photo1}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo2}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo3}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo4}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo5}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo6}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo7}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo8}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo9}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo10}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo11}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo12}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo13}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo14}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo15}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo16}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo17}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo18}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo19}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo20}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo21}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo22}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo23}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo24}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo25}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo26}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo27}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo28}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo29}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo30}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo31}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo32}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo33}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo34}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo35}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo36}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo37}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo38}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo39}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo40}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo41}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo42}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo43}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo44}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo45}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo46}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo47}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo48}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo49}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo50}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>
                            
                            <a id='lg-pictures' href={photo51}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo52}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo53}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo54}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo55}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo56}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo57}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo58}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo59}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo60}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo61}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo62}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo63}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo64}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo65}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo66}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo67}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo68}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo69}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo70}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo71}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo72}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo73}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo74}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo75}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo76}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo77}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo78}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo79}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo80}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo81}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo82}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo83}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo84}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo85}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo86}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo87}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo88}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo89}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo90}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo91}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo92}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo93}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo94}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo95}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo96}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo97}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo98}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo99}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo100}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo101}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo102}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo103}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo104}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo105}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo106}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo107}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo108}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo109}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo110}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo111}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo112}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo113}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo114}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo115}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo116}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo117}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo118}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo119}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo120}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo121}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo122}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo123}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo124}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo125}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo126}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo127}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo128}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo129}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo130}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo131}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo132}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo133}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo134}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo135}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo136}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo137}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo138}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo139}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo140}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo141}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo142}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo143}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo144}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo145}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo146}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo147}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo148}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo149}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo150}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo151}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo152}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>

                            <a id='lg-pictures' href={photo153}>
                              <img src={groupPhotoIcon} id='lg-group' className='d-none'/>
                            </a>


                          </LightGallery>

          </div>
        </div>
      </div>

      <span className='footer'>
        <img src={TowerImage} alt="" className="ms-0 ms-md-auto d-none d-md-block wheel-small p-absolute" />
        <img src={TowerImage} alt="" className="ms-0 ms-md-auto d-block d-md-none wheel-small" />
        <h2 className='text-primary footer-text mx-0 ps-0 mx-md-4 ps-md-3' >London 9 - 12 November 2023</h2>
        <img className='strip' src={strip} alt='footer' />
      </span>
    </div>
  );
};

export default PhotoGalleryPage;

