import React, { createContext, useContext, useState } from 'react';
import logo from './asset/images/logos/snb-logo-1.png';

const LogoContext = createContext();

export const useLogo = () => {
  return useContext(LogoContext);
};

export const LogoProvider = ({ children }) => {
  const [logoSrc] = useState(logo);

  return <LogoContext.Provider value={logoSrc}>{children}</LogoContext.Provider>;
};